<script setup>

const hostname = window.location.hostname
const siteName = hostname.charAt(0).toUpperCase() + hostname.slice(1)
</script>

<template>
  <footer>
    <p>
      Сервис "{{ siteName }}" / ООО "ФИНТЕХСЕРВИС" не является кредитной организацией и не оказывает финансовых услуг.
    </p>
    <p>
      ООО "ФИНТЕХСЕРВИС" ИНН 5248045655
      Нижегородская область, г.о. Город Нижний Новгород, г Нижний Новгород, ул Окский Съезд, дом 8, помещ./офис П3/303
    </p>

    <p>
      Общие условия и требования к заемщику Требования к заемщику: Возраст от 18
      до 75 лет; Постоянная прописка в любом регионе РФ; Любая кредитная история
    </p>

    <p>
      Условия получения: Первый заём до 30 000 рублей выдается по ставке 0% в
      случае своевременного погашения; Процентная ставка от 0,00 до 0,8% в день.
      Полная стоимость кредита (ПСК) составляет от 0,00% до 292,00% годовых.
    </p>

    <p>
      Минимальный период погашения займа: 5 дней, максимальный период погашения
      займа: 730 дней. В случае нарушения сроков, размер неустойки составляет
      0,10% от суммы просрочки в день, но не более 10% от суммы кредита; В
      случае длительной задержки выплаты информация будет передана в БКИ
    </p>
  </footer>
</template>

<style scoped>
footer {
  font-size: 8px;
  line-height: 12px;
  padding: 0 16px;

  max-width: 50%;
  width: 100%;
  margin: 40px auto 0;
}
@media (max-width: 768px) {
  footer {
    max-width: 86%;
  }
}
</style>
